import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Interactions } from "@sparefoot/segment-react";
import { SegmentContextProvider } from "@sparefoot/react-commons/segment";
import { Text } from "components/core/Typography";
import SearchPageContext from "pages/SearchPage/SearchPageContext";
import FiltersList from "./FiltersList";

import "./SearchFilters.scss";

const SearchFilters = ({
	filterLists,
	onChange,
	navigateOnChange,
	filtersName
}) => {
	const { setIsFiltering, isFiltering } = useContext(SearchPageContext);

	const handleFiltering = (f) => {
		// intercept the handle filtering change event so we can disable the UI
		if (navigateOnChange) setIsFiltering(!isFiltering);
		// update the redux store, but don't necessarily navigate
		onChange(f, navigateOnChange);
	};

	return (
		<SegmentContextProvider segmentCategory={Interactions.SEARCH}>
			<div
				className={classNames("search-filters", {
					filtering: isFiltering
				})}
			>
				<Text
					align="left"
					display="block"
					size="large"
					weight="bold"
				>
					Filter Results
				</Text>
				{filterLists.map(({ label, type, multiple, options }) => (
					<FiltersList
						filtersName={filtersName}
						key={label}
						onChange={handleFiltering}
						label={label}
						type={type}
						multiple={multiple}
						options={Object.values(options)}
					/>
				))}
			</div>
		</SegmentContextProvider>
	);
};

SearchFilters.defaultProps = {
	navigateOnChange: false
};
SearchFilters.propTypes = {
	filtersName: PropTypes.string.isRequired,
	navigateOnChange: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	filterLists: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SearchFilters;
