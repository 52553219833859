import React from "react";
import PropTypes from "prop-types";
import "./HTMLContent.scss";
import classNames from "classnames";

function createMarkup(content) {
	return { __html: content };
}

const HTMLContent = ({ content, className }) => (
	<div
		className={classNames("htmlContent", className)}
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={createMarkup(content)}
	/>
);

HTMLContent.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string.isRequired
};

export default HTMLContent;
