import { storageTypesFormatted } from "config/storageTypes";

export default function buildSisterPages(links, currentPageUri) {
	if (!links) {
		return null;
	}
	const showLink = (key) => links[key] !== currentPageUri;
	const transformLinks = (acc, key) => {
		acc.push({ url: links[key], title: storageTypesFormatted[key] });
		return acc;
	};
	return Object.keys(links).filter(showLink).reduce(transformLinks, []);
}
