import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Section } from "components/core/Page";
import { H3, Paragraph, Text } from "components/core/Typography";
import { Filter, MapPinFilled, Phone } from "components/core/Icons";
import { ClickToCall } from "components/brand/ClickToCall";
import noResults from "assets/images/no-results.svg";
import { getTollfree } from "store/modules/phone";
import "./NoResults.scss";

const NoResults = () => {
	const [phone, setPhone] = useState({});
	const dispatch = useDispatch();
	const handleFetchNumber = async (data) => {
		const {
			payload: { result }
		} = await dispatch(getTollfree(data));
		setPhone(result);
		return result;
	};
	return (
		<Section className="no-results">
			<img
				alt="no results"
				src={noResults}
			/>
			<div className="text">
				<H3
					size="xlarge"
					mediumSize="large"
				>
					No Storage Facilities
					<br />
					Were Found
				</H3>
				<Paragraph>
					Don&lsquo;t worry! We can help you get back on track.
				</Paragraph>
				<div className="options">
					<Filter fill="darkGray" />
					<Text>Update your filters</Text>
					<MapPinFilled fill="darkGray" />
					<Text>Search for a more specific location</Text>
					<Phone fill="darkGray" />
					<Text>
						Still having trouble finding the perfect storage?
						<br />
						Call us at:
						<ClickToCall
							showIcon={false}
							handleFetchNumber={handleFetchNumber}
							phone={phone}
						/>
					</Text>
				</div>
			</div>
		</Section>
	);
};

export default NoResults;
