import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Input } from "@sparefoot/react-commons";
import { Text } from "components/core/Typography";

import "./FiltersList.scss";

export const FilterItem = ({
	label,
	value,
	name,
	type,
	checked,
	onChange,
	filtersName
}) => {
	const filterItemId = `${filtersName}-${value}`;

	const segmentLabel = `${filtersName}-${name}-filter-${value}`;

	return (
		<div className={classNames("filter-item", type, { checked })}>
			<label
				htmlFor={filterItemId}
				className="filter-item__label"
			>
				<Input
					id={filterItemId}
					checked={!!checked}
					value={value}
					onChange={onChange}
					name={name}
					type="checkbox"
					segmentLabel={segmentLabel}
				/>
				<Text
					weight={checked ? "semiBold" : "regular"}
					size="small"
					color={checked ? "blue" : "darkGray"}
					last
				>
					{label}
				</Text>
			</label>
		</div>
	);
};

FilterItem.defaultProps = {
	type: "checkbox",
	onChange: () => {}
};

FilterItem.propTypes = {
	checked: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string,
	onChange: PropTypes.func
};

export const FiltersList = ({
	label,
	options,
	type,
	onChange,
	filtersName
}) => (
	<div className="filters-list">
		<Text
			align="left"
			display="block"
			weight="bold"
			size="small"
		>
			{label}
		</Text>
		{options.map((item) => (
			<FilterItem
				filtersName={filtersName}
				key={item.value}
				type={type}
				onChange={onChange}
				{...item}
			/>
		))}
	</div>
);

FiltersList.defaultProps = {
	onChange: () => {},
	multiple: true
};

FiltersList.propTypes = {
	filtersName: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			...FilterItem.propTypes
		}).isRequired
	).isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

export default FiltersList;
