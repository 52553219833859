import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "components/core/Button";
import { Cross } from "components/core/Icons";
import { Modal as CoreModal } from "@sparefoot/react-commons";

import "./Modal.scss";

function Modal({
	children,
	className,
	closable,
	desktopFullscreen,
	handleClose,
	isOpen
}) {
	useEffect(() => {
		// .modal-active style is found in main.scss
		// this prevents the body from scrolling behind the modal
		if (isOpen)
			document.querySelector("body").classList.add("modal-active");
		else {
			document.querySelector("body").classList.remove("modal-active");
		}
	}, [isOpen]);

	return (
		<CoreModal
			className={classnames(className, { desktopFullscreen })}
			closable={closable}
			isOpen={isOpen}
			handleClose={handleClose}
		>
			{closable && (
				<Button
					label="Close Modal"
					className="close-modal-btn"
					onClick={handleClose}
					segmentLabel="modal close button"
					theme="icon"
				>
					<Cross
						fill="lightGray"
						size="large"
					/>
				</Button>
			)}
			{children}
		</CoreModal>
	);
}

Modal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	closable: PropTypes.bool,
	desktopFullscreen: PropTypes.bool,
	handleClose: PropTypes.func,
	isOpen: PropTypes.bool
};

Modal.defaultProps = {
	closable: true,
	desktopFullscreen: true
};

export default Modal;
