import React, { useContext, useEffect } from "react";
import { Modal } from "components/core/Modal";
import { mediaQueries, useMediaQuery } from "hooks/useMediaQuery";
import SearchPageContext from "pages/SearchPage/SearchPageContext";
import SearchFilters from "components/search/SearchFilters/SearchFilters";
import { Button, TextButton } from "components/core/Button";

import "./FiltersModal.scss";

const FiltersModal = () => {
	const {
		showFilterModal,
		applySearch,
		setShowFilterModal,
		searchFilters,
		handleFilterChange
	} = useContext(SearchPageContext);

	const [belowMedium] = useMediaQuery([mediaQueries.belowMedium]);

	useEffect(() => {
		// close the modal if we size up past medium
		if (!belowMedium && showFilterModal) setShowFilterModal(false);
	}, [belowMedium, showFilterModal]);

	const handleCloseModal = () => {
		setShowFilterModal(false);
	};

	const handleApplyFilters = () => {
		handleCloseModal();
		applySearch();
	};

	return (
		<Modal
			closable
			className="filters-modal"
			isOpen={showFilterModal}
			handleClose={handleCloseModal}
		>
			<div className="filters-container">
				<SearchFilters
					filtersName="modal"
					filterLists={searchFilters}
					navigateOnChange={false}
					onChange={handleFilterChange}
				/>
			</div>
			<div className="buttons-container">
				<Button
					theme="primary"
					label="Apply Filters"
					onClick={handleApplyFilters}
				>
					Apply Filters
				</Button>

				<TextButton
					label="Cancel"
					onClick={handleCloseModal}
				>
					Cancel
				</TextButton>
			</div>
		</Modal>
	);
};

export default FiltersModal;
