import React, { useContext } from "react";
import { orderBy } from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";

import searchPageContext from "pages/SearchPage/SearchPageContext";
import { Section } from "components/core/Page";
import SearchToolbar from "components/search/SearchToolbar/SearchToolbar";
import FacilityCards from "components/search/FacilityCards/FacilityCards";
import NoResults from "./NoResults";

import "./SearchResults.scss";

const SearchResults = ({ facilityCards }) => {
	const { isFiltering, totalListings } = useContext(searchPageContext);
	const sortedFacilityCards = orderBy(facilityCards, ["sortId"], ["asc"]);
	return (
		<Section className="search-results">
			{totalListings >= 0 && <SearchToolbar count={totalListings} />}
			{facilityCards.length === 0 && <NoResults />}
			<div
				className={classnames("search-results-items", { isFiltering })}
			>
				<FacilityCards totalListings={totalListings} cards={sortedFacilityCards} />
			</div>
		</Section>
	);
};

SearchResults.propTypes = {
	facilityCards: PropTypes.arrayOf(PropTypes.object),
};

export default SearchResults;
