import React, { useContext } from "react";
import SearchFilters from "components/search/SearchFilters/SearchFilters";
import SearchPageContext from "pages/SearchPage/SearchPageContext";
import "./FiltersSidebar.scss";

const FiltersSidebar = () => {
	const { searchFilters, handleFilterChange } = useContext(SearchPageContext);
	return (
		<div className="filters-sidebar">
			<div className="filters-container">
				<SearchFilters
					navigateOnChange
					filtersName="sidebar"
					filterLists={searchFilters}
					onChange={handleFilterChange}
				/>
			</div>
		</div>
	);
};

FiltersSidebar.propTypes = {};

export default FiltersSidebar;
