import React from "react";
import PropTypes from "prop-types";
import { Link } from "components/core/Link";
import { H3 } from "components/core/Typography";

import "./SisterPages.scss";

const SisterPages = ({ header, cityName, links }) => (
	<div className="sister-pages">
		<H3 size="large">{header}</H3>
		<div className="sister-page-links">
			{links.map(({ title, url }) => (
				<div
					className="sister-link"
					key={title}
				>
					<Link
						underline
						hover
						nowrap
						weight="semiBold"
						href={`/${url}`}
						segmentLabel={title}
					>
						{`${cityName} ${title}`}
					</Link>
				</div>
			))}
		</div>
	</div>
);

SisterPages.propTypes = {
	header: PropTypes.string,
	cityName: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.object)
};

export default SisterPages;
