import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
	toggleOnSearchFilter,
	applySearch,
	deactivateSearchFilterItem,
	setSortOrder
} from "store/modules/search";

const useSearchFilters = (filterGroupKeys, queryParams) => {
	const dispatch = useDispatch();

	// Accepts an array of filter group keys, to allow the hook to provide an ordered and customized list of filters.
	// The filter group keys also act like a whitelist, making sure that we
	// don't try to scrape anything from the url we don't expect.

	// This exports a handler, and the list of filters with their checked state

	// These are the actions we have to deal with:
	// 1. Read and apply query params to filter state. This happens in the useEffect, on mount.
	// 2. Handle filter option changes, updating the redux store and optionally navigating.
	// 3. On navigation, build a new link with the active filters from the redux store.

	const searchFilters = useSelector((state) =>
		filterGroupKeys.map((k) => state.search.searchFilters[k])
	);

	const selectedSort = useSelector((state) => state.search.sort);

	const { pathname } = useLocation();

	const handleApplySearch = () => {
		dispatch(applySearch(pathname, queryParams));
	};

	const handleFilterChange = ({ target }, navigateOnChange) => {
		const { name, value, checked } = target;
		// if it's checked, check to see if it's a multiple group
		if (checked) {
			dispatch(toggleOnSearchFilter({ name, value }));
		} else {
			// unchecking works the same for both single and multiple groups
			dispatch(deactivateSearchFilterItem({ name, value }));
		}

		if (navigateOnChange) {
			// dispatch this thunk so that we have an updated state when we navigate
			// redux -> params
			handleApplySearch();
		}
	};

	const handleSortChange = (value) => {
		dispatch(setSortOrder(value));
		handleApplySearch(); // always re-render on sort change
	};

	return {
		handleFilterChange,
		searchFilters,
		handleSortChange,
		selectedSort,
		applySearch: handleApplySearch
	};
};

export default useSearchFilters;
