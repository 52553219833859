const headlineForPageType = {
	// Note: we use 'in' for search results and 'near' for all other results
	searchPage: "Self-storage facilities in",
	cityLandingPage: "Self-storage facilities near",
	"climate-controlled": "Climate controlled storage units near",
	"24-hour-access": "24-hour access storage units near"
};

export default function formatPageTypeTitle(subPageType) {
	return (
		headlineForPageType[subPageType] || headlineForPageType.cityLandingPage
	);
}
