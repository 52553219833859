import React from "react";
import PropTypes from "prop-types";
import { H2, Text } from "components/core/Typography/Typography";
import { Section } from "components/core/Page";

import "./HowItWorks.scss";

export default function HowItWorks({ steps }) {
	return (
		<Section
			flush
			className="how-it-works"
		>
			<H2
				size="large"
				mediumSize="standard"
			>
				How It Works
			</H2>
			<div className="steps">
				{steps.map((step, index) => (
					<div
						className="step"
						key={step}
					>
						<span className="number">{index + 1}</span>
						<Text
							className="text"
							size="standard"
							mediumSize="tiny"
							weight="semiBold"
							color="gray"
							last
						>
							{step}
						</Text>
					</div>
				))}
			</div>
		</Section>
	);
}

HowItWorks.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.string)
};
