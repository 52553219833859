import React from "react";
import PropTypes from "prop-types";
import { H3, Paragraph, Text } from "components/core/Typography";
import { Link } from "components/core/Link";
import { CircleArrow } from "components/core/Icons";

import "./NearbyCities.scss";

const NearbyCity = ({ city }) => {
	const formattedCity = `${city.city}, ${city.state}`;
	const segmentProperties = { value: city };
	const distanceText =
		city.distance > 1
			? `${city.distance} miles away`
			: `${city.distance} mile away`;
	return (
		<Link
			href={`/${city.uri}`}
			weight="semiBold"
			segmentLabel="city"
			className="nearby-city-card"
			segmentProperties={segmentProperties}
		>
			<div>
				<Text
					weight="semiBold"
					color="blue"
				>
					{formattedCity}
				</Text>
				<Paragraph
					size="small"
					color="lightGray"
					last
				>
					{distanceText}
				</Paragraph>
			</div>
			<div className="nearby-city-button">
				<CircleArrow size="xlarge" />
			</div>
		</Link>
	);
};

NearbyCity.propTypes = {
	city: PropTypes.object
};

const NearbyCities = ({ nearbyCities }) => (
	<div className="nearby-cities">
		<H3 size="large">Browse Self-Storage Units in Nearby Cities</H3>
		<div className="nearby-city-container">
			{nearbyCities.map((city) => (
				<NearbyCity
					city={city}
					key={city.contentId}
				/>
			))}
		</div>
	</div>
);

NearbyCities.propTypes = {
	nearbyCities: PropTypes.arrayOf(PropTypes.object)
};

export default NearbyCities;
